import '../styles/loader.css'

const Lodaer = () => {
    return(
        <div className="loader-holder">
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
            
        
    )
}

export default Lodaer