const Lines = () => {
    return(
        <div className='lines-bg d-flex justify-content-around'>
            <span className='border-r opacity-05'></span>
            <span className='border-r opacity-05'></span>
            <span className='border-r opacity-05'></span>
            <span className='border-r opacity-05'></span>
            <span className='border-r opacity-05'></span>
            <span className='border-r opacity-05'></span>
        </div>
    )
}

export default Lines